.modal-opened {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: var(--z-index-modal-level);
}

.modal-close {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-modal-level);
}

.modal-card {
	min-height: unset;
	max-width: 90rem;
	width: 80%;
	margin: 0 2rem;
	border-radius: 5px;
	max-height: 90vh;
	overflow-x: auto;
	padding: 1rem;
}

.modal-card-body label {
	width: 100%;
}

.modal-card-body label .text-field {
	width: 100%;
	margin: 1rem 0;
	resize: none;
}

.modal-card-footer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

.modal-btn-disabled {
	background-color: var(--colour-text-grey);
	color: var(--colour-error-second);
	cursor: not-allowed;
}

.modal-opened {
	backdrop-filter: blur(5px);
	/* This value is not in var as this is being used only once here */
	background-color: rgb(0 0 0 / 70%);
}

.input-group .text-field {
	margin: 8px 0;
	width: 30rem;
}

.modal-card .btn {
	margin: 0.5rem 0;
}

@media screen and (max-width: 768px) {
	.modal-card {
		max-width: 80vw;
	}

	.input-group .text-field {
		margin: 8px 0;
		width: 69vw;
	}
}
