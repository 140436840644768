@import './Pages/ProfilePage/ProfilePage.css';
:root {
	--z-index-modal-level: 10;
	--z-index-alert-level: 11;
	--z-index-profile-modal-level: 9;
	--z-index-card-level-2: 8;
	--z-index-card-level-1: 7;
	--z-index-card-level-0: 6;
	--z-index-low-level-1: 5;
	--z-index-low-level-2: 4;

	--colour-secondary-second: #698dc7;
	--box-shadow-level-1: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
	--box-shadow-level-2: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	--colour-text-grey: #2d333a;
	--box-shadow-level-3: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

[data-theme='dark'] {
	--colour-secondary-second: #141f30;
	--colour-text-grey: #8b949e;
}

.app {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 8rem calc(100vh - 13rem) 5rem;
	grid-template-areas:
		'navbar'
		'main-content'
		'footer';
	min-height: 100vh;
	max-height: 100%;
	margin: auto;
	width: 100%;
}

.relative {
	position: relative;
}

.app-content {
	position: relative;
	grid-area: main-content;
	max-width: 90rem;
	width: 100%;
	margin: 0 auto;
	padding: 0 2rem;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.modal-opened {
	z-index: var(--z-index-modal-level);
}

.app-footer {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-area: footer;
}

.btn-link {
	cursor: pointer;
}

.width-100 {
	width: 100%;
}

.not-selectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.btn-fab {
	display: flex;
	right: 10rem;
	height: 5rem;
	width: 5rem;
	justify-content: center;
	align-items: center;
	border-radius: 5rem;
	border: 1px solid transparent;
	padding: 1rem;
	cursor: pointer;
	aspect-ratio: unset;
	z-index: var(--z-index-card-level-2);
	animation: fab-animation 0.3s;
}
@keyframes fab-animation {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}

.display-none {
	display: none;
}
.loader-div {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: var(--z-index-low-level-2);
	display: flex;
	justify-content: center;
	align-items: center;
}
.btn-disabled {
	cursor: not-allowed;
}
.global-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: var(--z-index-modal-level);
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 768px) {
	.btn-fab > p {
		display: none;
	}
	.btn-fab {
		right: 2rem;
		bottom: 3rem;
		padding: 0.5rem;
	}
}
