.user-detail {
	width: 100%;
	min-height: min-content;
	max-height: 50rem;
	overflow-y: auto;
	margin-top: 2rem;
	padding-top: 1rem;
	display: flex;
}

.user-img {
	margin: 2rem;
	flex: 0.5;
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.user-img img {
	aspect-ratio: 1;
	object-fit: cover;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.user-img:hover img {
	filter: blur(1.2px);
}

.profile-pic-edit-btn {
	visibility: hidden;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transform: scale(2);
}

.user-img:hover .profile-pic-edit-btn {
	visibility: visible;
	z-index: var(--z-index-low-level-2);
	transition: all 0.2s ease-in-out;
}

.edit-profile-button {
	margin: 0 1rem;
	padding: 1rem;
	cursor: pointer;
	border-radius: 1rem;
	background-color: var(--colour-primary-second);
	color: var(--colour-text-white);
}

.user-img img {
	max-width: 14rem;
	height: 14rem;
	border-radius: 50%;
	display: block;
}

.user-info {
	flex: 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.user-info > * {
	margin-bottom: 1rem;
	max-width: 50rem;
}

.user-info-followers {
	display: flex;
	width: 100%;
	gap: 2rem;
	justify-content: flex-start;
}

.user-info-bio {
	color: var(--colour-text-grey);
}

.user-info-link {
	position: relative;
	color: var(--colour-secondary);
	text-decoration: none;
}

.user-info-link:after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 2px;
	bottom: 5px;
	left: 0;
	background-color: var(--colour-secondary);
	transform-origin: bottom right;
	transition: transform 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.user-posts {
	width: 100%;
	flex-direction: column;
	margin-top: 2rem;
	padding-top: 1rem;
	display: flex;
	overflow: hidden;
}

.single-user-post {
	overflow-x: hidden;
	position: relative;
	width: 100%;
	min-height: 15rem;
	margin: 2rem auto;
	background-color: var(--colour-secondary-second);
	padding: 2rem 2rem 0 2rem;
	transition: all 0.4s ease-in-out;
	border-radius: 1rem;
	opacity: 0;
	animation-name: card-animation;
	animation-duration: 300ms;
	animation-fill-mode: forwards;
	animation-delay: var(--delay);
}

@keyframes card-animation {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}
	50% {
		opacity: 0.8;
		transform: translateX(-5%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.single-user-post-comments-opened {
	min-height: 60rem;
}

.bookmark-icon {
	top: -5rem;
	right: 1rem;
	position: absolute;

	cursor: pointer;
	z-index: 1;
	transition: all 0.2s ease-in-out;
}

.single-user-post:hover .bookmark-icon {
	top: 0;
}

.bookmark-icon:hover {
	color: var(--colour-accent-third);
}

.bookmarked-icon {
	top: 0;
	right: 1rem;
	position: absolute;
	cursor: pointer;
	z-index: 1;
	transition: all 0.2s ease-in-out;
	color: var(--colour-accent-third);
}

.user-post-comments-container {
	position: absolute;
	bottom: 0;
	left: 0;
	top: 2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--colour-black-third);
	border-radius: 1rem;
	transition: all 0.4s ease-in-out;
	padding: 2rem 1rem;
	overflow-y: scroll;
}

.single-user-post .user-post-comments-container-closed {
	left: 100%;
}

.all-comments-header {
	top: -2rem;
	position: sticky;
	display: flex;
	align-items: center;
	background-color: var(--colour-black-third);
}

.comment-close-btn {
	cursor: pointer;
}

.add-comment-text-field {
	width: 100%;
}

.post-header {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	width: 100%;
}

.post-header img {
	object-fit: cover;
	width: 5rem;
	height: 5rem;
	border-radius: 50%;
}

.user-info-link:hover:after {
	transform: scale(1);
	transform-origin: bottom left;
}

.post-image {
	margin-left: 6rem;
	height: min-content;
	max-height: 60rem;
	border-radius: 1rem;
}

.post-image img {
	width: 100%;
	height: 100%;
	max-height: 60rem;
	object-fit: contain;
}

.post-content {
	margin-left: 6rem;
}

.post-cta {
	margin-left: 6rem;
	display: flex;
	justify-content: space-around;
}

.cta-button {
	cursor: pointer;
	margin: 1rem 0;
	border-radius: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	padding: 1rem;
}

.cta-button:hover {
	background-color: var(--colour-primary-second);
}

.edit-delete-container {
	position: absolute;
	display: flex;
	top: 1rem;
	right: 1rem;
	gap: 1rem;
}
.edit-delete-container :hover {
	cursor: pointer;
}

.post-content input.text-field {
	width: 100%;
}

@media screen and (max-width: 768px) {
	.user-detail {
		flex-direction: column;
	}

	.post-content {
		margin: 2rem 0 0 0;
	}

	.post-cta {
		margin: 1rem 0 0 0;
	}

	.post-image {
		margin-left: 0;
		max-height: 40rem;
	}
	.post-image img {
		max-height: 40rem;
	}

	.cta-button p {
		display: none;
	}
}
