.create-post-body {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	gap: 1rem;
	padding: 1rem;
}

.create-post-body > * {
	flex-grow: 1;
	min-width: 20rem;
	max-width: 60rem;
}

.create-post-body label .text-field {
	width: 100%;
	margin: 1rem 0;
}

.image-holder {
	min-width: 20rem;
	max-width: 60rem;
	min-height: 10rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: cover;
	border-radius: 5px;
	cursor: pointer;
}

.image-preview {
	max-height: 25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 5px;
}
textarea {
	resize: none;
}
