.navbar {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.navbar-brand {
	color: var(--colour-text-white);
	font-size: calc(2.2rem + 0.9vw);
	font-weight: 500;
	margin: 0 1rem;
}

.navbar .text-field {
	flex-grow: 1;
	height: 4.5rem;
	font-size: calc(1.2rem + 0.9vw);
	font-weight: 500;
}

.navbar-cta {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.menu-burger {
	width: 4rem;
	height: 4rem;
}

.menu-burger > div {
	width: 4rem;
	height: 5px;
	background-color: var(--colour-primary);
	margin: 6px 0;
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), scale 0.2s ease-in-out;
}

.menu-burger.menu-burger-opened > div:nth-child(1) {
	transform: rotateZ(34deg) scale(0.6, 0.8) translateY(0.9px) translateX(11px);
}

.menu-burger.menu-burger-opened > div:nth-child(2) {
	transform: rotate(180deg) scale(0.8) translateX(0px);
}

.menu-burger.menu-burger-opened > div:nth-child(3) {
	transform: rotateZ(146deg) scale(0.6, 0.8) translateY(0.8px) translateX(-11px);
}

.searchbar-holder {
	position: relative;
}

.searchbar-holder .text-field {
	margin: 1rem 0;
}

.searchbar-holder input:focus + .search-data {
	height: min-content;
	max-height: 30rem;
	transform: scaleX(1);
	transform-style: preserve-3d;
}

.search-data {
	overflow: auto;
	position: absolute;
	top: 5.8rem;
	left: 0;
	z-index: var(--z-index-card-level-2);
	max-height: 30rem;
	transition: all 0.3s ease-in-out;
	width: 100%;
	background-color: var(--colour-secondary-second);
	border-radius: 1rem;
	transform: scaleX(0);
	transform-origin: right;
}

.search-data div {
	height: 5rem;
	width: 100%;
	display: flex;
	gap: 2rem;
	align-items: center;
	padding: 0 1rem;
	margin: 1rem 0;
	cursor: pointer;
}
.search-data div img {
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
}

.sidebar {
	overflow-y: auto;
	overflow-x: hidden;
	max-width: 7rem;
	min-width: 7rem;
	height: 100%;
	max-height: calc(100vh - 13rem);

	position: fixed;
	top: 8rem;
	right: 0;

	color: var(--both-theme-white);
	background-color: var(--colour-secondary-second);
	border-radius: 2rem 0 0 2rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	z-index: var(--z-index-low-level-1);
	transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.sidebar-opened {
	width: 100%;
	max-width: 17rem;
	min-width: 14rem;

	justify-content: flex-start;
	align-items: flex-start;
}

.sidebar-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	height: 4rem;
	padding: 5px;
	margin: 1rem;
	border-radius: 1rem;
	width: fit-content;
}

.sidebar-item:hover {
	background-color: var(--colour-primary-second);
	cursor: pointer;
}

.sidebar-item:last-child {
	margin-top: auto;
}

.sidebar-item > p {
	display: none;
	margin: 0 1rem;
}

.sidebar-opened .sidebar-item > p {
	display: block;
}

@media screen and (max-width: 1071px) {
	.sidebar {
		right: -20rem;
		transition: all 0.3s ease-in;
		border-radius: 0;
	}

	.sidebar-item > p {
		display: block;
	}

	.sidebar-opened {
		max-height: calc(100vh - 15rem);
		width: 100%;
		min-width: 100%;
		right: 0rem;

		justify-content: flex-start;
		align-items: center;
		transition: all 0.3s ease-in;
	}
}
