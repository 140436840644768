.who-to-follow-div {
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	scrollbar-width: none;
	width: 100%;
	height: min-content;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
}

.who-to-follow-card {
	overflow: hidden;
	display: flex;
	flex: 1 0 auto;
	margin: 1rem;
	max-width: 30rem;
	height: 15rem;
	scroll-snap-align: start;
	background-color: var(--colour-secondary-second);
	border-radius: 10px;
	box-shadow: var(--box-shadow-level-3);
}

.who-to-follow-card img {
	width: 12rem;
	height: 100%;
	object-fit: cover;
}

.who-to-follow-card .user-details {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
	height: 100%;
	max-width: 90%;
	padding: 1rem;
	margin: auto 1rem;
}

.user-details p {
	width: 13ch;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.user-details .btn {
	width: 100%;
}

.div-scroll-btn {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4rem;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	border-radius: 50%;
	width: 4rem;
	background-color: var(--colour-secondary);
}

.div-scroll-btn.right {
	right: 1rem;
}

.div-scroll-btn.left {
	left: 1rem;
}
